import React from "react";
import "../../style/herobanner/index.scss"
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import { Link } from "react-router-dom";

const Herobanner = (props) => {

  const isSingleImage = props.imageData.length === 1;

  return (
    <>
      <Carousel 
      showThumbs={false} 
      className="Herobanner !max-h-full" 
      emulateTouch={!isSingleImage}
      infiniteLoop={!isSingleImage} 
      preventMovementUntilSwipeScrollTolerance={!isSingleImage}
      showStatus={false}
      showIndicators={!isSingleImage}>
        {props.imageData.map((data) => {
          return (<>
            <div className="flex flex-col-reverse md:flex-row items-center justify-center">

              {/* Image Size 1929 * 828 px */}
              {/* <div className={data.btnName !== "none" ? "HerobannerData" : "HerobannerData"}> */}
              <div className={"w-full md:w-2/5 p-4 pt-0 flex flex-col justify-center"}>
                <div className="HerobannerHeading">
                  {data.title}
                </div>
                <div className={data.btnName !== "none" ? "HerobannerText " : "HerobannerText"}>
                  {data.text}
                </div>
                {data.text2 && (
                  <>
                  <br/>
                   <div className={data.btnName !== "none" ? "HerobannerText " : "HerobannerText"}>
                  {data.text2}
                </div></>)}
                {data.btnName !== "none" ? <Link to={data.btnLink} style={{ textDecoration: "none", color: data.btnTextColor }} target="_blank" >
                  <div className="HerobannerBtn" style={{ background: data.btnColor }}>
                    {data.btnName}
                  </div>
                </Link> : ""}

              </div>
              {/* <img src={data.imageLink} alt="1imag" className="imageTag" /> */}
              <div class="image-container-magazine flex items-center justify-center !h-[300px] sm:!h-[550px] md:!h-[750px] !w-2/5 xs:!w-2/5 sm:!w-2/5 md:!w-2/3">
                <img src={data.imageLink} alt="1imag" class="imageTagMagazine" />
              </div>



            </div>
          </>)
        })}


      </Carousel>

    </>
  );
};

export default Herobanner;
