import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import AdvertiseAgreementAccordian from "../AdvertiseAgreementAccordian/AdvertiseAgreementAccordian";

const WebPrintRates = () => {
    return (
        <>
            <div>
                <div className="ratesTabTitle">Web Advertising Rate</div>


                <div className="agreementButtonContainer">
                    <h3>To advertise with 22 West Media, contact <a href="mailto:advertising@22westmedia.com" style={{color: "#4cc8f4" }}>advertising@22westmedia.com</a></h3>
{/*                    
                    <NavLink to="https://drive.google.com/file/d/11cXcVG9etP-12cTp436obLnZV828WTU5/view?usp=sharing" target="_blank" className="workOrderForm">Web Advertising Agreement</NavLink>
                    <NavLink to="https://csulb.qualtrics.com/jfe/form/SV_cBfXNQ8COQGqA7k" target="_blank" className="workOrderForm">Work Order Form</NavLink>
                 */}
                </div>

                <div class="web-print-content">
                    <div class="content-item">
                        <div class="image-container">
                            <img src="image/Advertisements/advertising-web-sample-1.jpg" alt="Web Sample 1" />
                        </div>
                        <div class="ratesDescription">
                            <h2>1. Full Width Ad</h2>
                            <p>1660 (W) X 250 (H) PX</p>
                            <p>$300 per month (solo placement)</p>
                            <p>$150 per month (in rotation)</p>
                        </div>
                    </div>
                    <div class="content-item">
                        <div class="image-container">
                            <img src="image/Advertisements/advertising-web-sample-2.jpg" alt="Web Sample 2" />
                        </div>
                        <div class="ratesDescription">
                            <h2>2. Vertical Box Ad</h2>
                            <p>300 (W) X 600 (H) PX</p>
                            <p>$200 per month (solo placement)</p>
                            <p>$100 per month (in rotation)</p>
                            <br />
                            <h2>3. Small Box Ad</h2>
                            <p>300 (W) X 300 (H) PX</p>
                            <p>$150 per month (solo placement)</p>
                            <p>$75 per half month</p>
                        </div>
                    </div>

                    <div className="ratesTabTitle">Print Advertising Rate</div>

                    <div class="content-item">
                        <div class="image-container">
                            <img src="image/Advertisements/advertising-print-sample-1.jpg" alt="Print Sample 1" />
                        </div>
                        <div class="ratesDescription">
                            <h2>Base Rates For Black & White Display Ads</h2>
                            <p>$550 Full Page: 8.50” x 11.00”</p>
                            <p>$350 Half Page: Horizontal: 8.50”x5.50” or Vertical: 4.25”x11.00”</p>
                            <p>$150 Quarter Page: Horizontal: 9.75”x3.188” or Vertical: 4.25”x5.50”</p>
                            <p>$100 Eighth Page: Horizontal: 4.875”x3.188” or Vertical: 2.125”x5.50”</p>
                            <br />
                            <h2>Full Color</h2>
                            <p>Add $300   Full Page Ad</p>
                            <p>Add $180   Half Page Ad</p>
                            <p>Add $110   Quarter/Eighth Page Ad</p>
                            <p>Formats accepted for advertisement artwork:
                                We prefer PDF and TIFF files at 300dpi. Native files (.psd, .ai, or .eps) should be flattened with fonts outlined.</p>
                        </div>
                    </div>

                    <div class="content-item">
                        <div class="image-container">
                            <img src="image/Advertisements/advertising-print-sample-2.jpg" alt="Print Sample 2" />
                        </div>
                        <div class="ratesDescription">
                            <h2>Spring Semester Discounts</h2>
                            <p><h3>Pre-paid Discount: 5%</h3></p>
                            <p>For multiple runs (2 ads/semester),
                                advertisers are encouraged to pay up front, and as added incentive, there is a prepay discount</p>
                            <br />
                            <p><h3>Student and Campus</h3></p>
                            <p>CSULB students as well as campus organizations will receive a 30% discount on regular ad pricing.</p>
                            <p>The student and campus discount may not be combined with multiple-run or pre-pay discount.</p>
                            <br />
                            <p><h3>Multiple Runs:</h3></p>
                            <p>Open Run: 0%</p>
                            <p>2-3 Runs: 5%</p>
                            <p>4-5 Runs: 10%</p>
                            <p>6+ Runs: 30%</p>
                        </div>
                    </div>
                </div>

                <AdvertiseAgreementAccordian />

                <div className="agreementButtonContainer">
                    <h1 className="advertiseTitle">Advertise with us</h1>

                    {/* <NavLink to="https://drive.google.com/file/d/11cXcVG9etP-12cTp436obLnZV828WTU5/view?usp=sharing" target="_blank" className="workOrderForm">Web Advertising Agreement</NavLink>
                    <NavLink to="https://csulb.qualtrics.com/jfe/form/SV_cBfXNQ8COQGqA7k" target="_blank" className="workOrderForm">Work Order Form</NavLink> */}
                </div>
                     <h3>To advertise with 22 West Media, contact <a href="mailto:advertising@22westmedia.com" style={{color: "#4cc8f4" }}>advertising@22westmedia.com</a></h3>


            </div>
        </>
    );
}

export default WebPrintRates;