import React from 'react';
import { useAtom } from 'jotai';
import { DarkModeState } from '../../Jotai/index';
import "../../style/ContactInfoRadio/index.scss"; 
import { NavLink } from 'react-router-dom';

const ContactInfoRadio = () => {
  const [darkMode] = useAtom(DarkModeState);

  // Function to open email client
  const sendEmail = (emailAddress) => {
    window.location.href = `mailto:${emailAddress}`;
  };

  // Function to open link
  const openLink = (url) => {
    window.open(url, '_blank');
  };

  return (
    <div className={darkMode ? "bottom-section darkmode" : "bottom-section"}>
      {/* <div className={darkMode ? "bottom-card music-feature-card":"bottom-card music-feature-card whitemode"}>
        <h2>Feature your music on Radio!</h2>
        <p>Looking for a platform for your songs? 22 West Radio is for you! Reach us at: info@22westmedia.com</p>
        <button onClick={() => sendEmail('info@22westmedia.com')}>CONTACT</button>
      </div> */}
      <div className={darkMode ? "bottom-card questions-card":"bottom-card questions-card whitemode"}>
        <h2>For general inquiries, contact:</h2>
        {/* <p>Email Radio GM Michael Hinojos, RadioManager@22WestMedia.com</p> */}
        {/* <p>Email General Manager Morgan Barela, mbarela@22WestMedia.com</p> */}
        <p>Morgan Barela Student General Manager <p>📧 mbarela@22westmedia.com</p></p>
        <button onClick={() => sendEmail('mbarela@22WestMedia.com')}>EMAIL</button>
      </div>
     
      <div className={darkMode ? "service-request-card discord-card":"service-request-card discord-card"}>
        <h2>DJ/Music/Playlist Requests!</h2>
        <div className="service-tag">22 West Radio Services</div>
          <NavLink to="https://csulb.qualtrics.com/jfe/form/SV_0AFV8GmQqYbzU46" target="_blank" className="infoCardButton">
            <button>SUMIT REQUEST</button>
          </NavLink>
      </div>

      {/* <div className={darkMode ? "bottom-card discord-card":"bottom-card discord-card whitemode"}>
        <h2>We have a 22 West Radio Discord!</h2>
        <p>In order to join, please click this button below</p>
        <button onClick={() => openLink('https://discord.gg/A8agMWVwxX')}>INFO</button>
      </div> */}
    </div>
  );
};

export default ContactInfoRadio;
