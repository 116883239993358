import React from "react";
import "../../style/Videobanner/index.scss"
import { NavLink } from "react-router-dom";

const Videobanner = ({id}) => {
  return (
    <>

    <div id={id} className="Videobanner">
        <div className="VideobannerMain">
            {/* <div className="VideobannerMain_1">
                <img src="/image/Videobanner/vb1.png" alt="vb1" className="VideobannerMain_1img"/>
            </div> */}


            <div className="VideobannerMain_3">

            <div className="VideobannerMain_3title">22 West TV Instagram</div>
            <NavLink to="https://www.instagram.com/22westtv" className="navLinkDesign"  target="_blank">
            <div className="VideobannerMain_3btn">Follow Us</div>
            </NavLink>
            </div>

            <div className="VideobannerMain_2">

                <div className="VideobannerMain_2title">Watch our latest content!</div>
                <NavLink to="https://www.youtube.com/@22WestTV?sub_confirmation=1" className="navLinkDesign"  target="_blank">
                <div className="VideobannerMain_2btn">Subscribe to our YouTube channel!</div>
                </NavLink>
            
            </div>



        </div>
    </div>
              
    </>
  );
};

export default Videobanner;
