import React from "react";
import "../../style/herobanner/index.scss"
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import { Link } from "react-router-dom";
import { useAtom } from "jotai";
import { DarkModeState } from "../../Jotai/index";

const Aboutbanner = (props) => {
  const [darkMode, ] = useAtom(DarkModeState);
  const isSingleImage = props.imageData.length === 1;
  const buttonData = props?.buttonData;
  return (
    <>
              <Carousel 
              showThumbs={false} 
              className="Herobanner !max-h-full" 
              emulateTouch={!isSingleImage}
              infiniteLoop={!isSingleImage} 
              preventMovementUntilSwipeScrollTolerance={!isSingleImage}
              showStatus={false}
              showIndicators={!isSingleImage}>
             { props.imageData.map((data)=>{
                return(<>
                    {/* <div className="HerobannerCom"> */}
                    <div className="flex flex-col-reverse md:flex-row items-center justify-center">

                     {/* Image Size 1929 * 828 px */}
                    {/* <div className="aboutData"> */}
                    <div className="w-full md:w-2/5 p-4 pt-0 flex flex-col justify-center">
                        <div className="HerobannerHeading">
                      {data.title}
                        </div>
                        <div className="aboutText">
                        {data.text}
                        
                        </div>

                        <div className="aboutText">
                        {data.text2}
                        </div>
                        {buttonData? 
                          <div className={darkMode ? "btnShowmore" : "btnShowmore lightmodeBtn"} >
                            <a href={buttonData.link}>{buttonData.buttonText}</a>
                          </div>
                        :null}
                    </div>
                    <img src={data.imageLink} alt="1imag"  className="imageTag "/>

                </div>
                </>)
             })}
             
              
            </Carousel>
   
    </>
  );
};

export default Aboutbanner;
