import React from "react";
import "../../style/Disclaimer/index.scss";
import "../../style/WhatHappening/index.scss";



const Disclaimer = () => {
  return (
    <div className="Disclaimer">
      <p>
        All students, regardless of sex, race, color, national origin, or any other protected identity, who are interested in the program’s mission and focus, are welcome and eligible to participate in all Associated Students, Inc. (ASI) events and programs.
      </p>
    </div>
  );
};

export default Disclaimer;
