import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import AdvertiseAgreementAccordian from "../AdvertiseAgreementAccordian/AdvertiseAgreementAccordian";

const RadioRates = () => {
    return (
        <>
            <div>
                <div className="ratesTabTitle">Radio Advertising Rate</div>
                <div className="agreementButtonContainer">
                     <h3>To advertise with 22 West Media, contact <a href="mailto:advertising@22westmedia.com" style={{color: "#4cc8f4" }}>advertising@22westmedia.com</a></h3>

                    {/* <NavLink
                        to="https://drive.google.com/file/d/11cXcVG9etP-12cTp436obLnZV828WTU5/view?usp=sharing"
                        target="_blank"
                        className="workOrderForm"
                    >
                        Web Advertising Agreement
                    </NavLink>
                    <NavLink
                        to="https://csulb.qualtrics.com/jfe/form/SV_cBfXNQ8COQGqA7k"
                        target="_blank"
                        className="workOrderForm"
                    >
                        Work Order Form
                    </NavLink> */}
                </div>

                {/* <div className="advertisingUpdate-2024">
                    <p className="advertisingUpdateTitle">
                    RADIO ADVERTISING UPDATE 2024
                    </p>
                    <p>
                    THE LOWEST RATES: Budgeting for advertising can be a pain, and we understand that. We are committed to offering businesses in our community a reasonable and affordable way to spread the word about their products and services.
                    </p>
                    <p>
                    REACH & STATISTICS 
                    <br />
                    22 West Radio broadcasts to the student & campus body of over 40,000 persons, as well as into the community far beyond Long Beach State. Our most typical listener is a Los Angeles resident in some way related to LBSU or the City of Long Beach, and on average we reach over 10,000 listeners a month, more than a quarter being international. We use Triton Digital internet streaming service to calculate listenership via the many portals made accessible to our audience on internet  and mobile platforms. 
                    </p>
                </div> */}

                <div className="coverageMap">

                    <p className="advertisingUpdateTitle">
                        RADIO ADVERTISING UPDATE 2024
                    </p>
                    <p>
                        THE LOWEST RATES: Budgeting for advertising can be a pain, and we
                        understand that. We are committed to offering businesses in our
                        community a reasonable and affordable way to spread the word about
                        their products and services.
                    </p>
                    <p>
                        REACH & STATISTICS
                        <br />
                        22 West Radio broadcasts to the student & campus body of over 40,000
                        persons, as well as into the community far beyond Long Beach State.
                        Our most typical listener is a Los Angeles resident in some way
                        related to LBSU or the City of Long Beach, and on average we reach
                        over 10,000 listeners a month, more than a quarter being
                        international. We use Triton Digital internet streaming service to
                        calculate listenership via the many portals made accessible to our
                        audience on internet and mobile platforms.
                    </p>

                    <img
                        src="image/Advertisements/advertising-radio-map.jpg"
                        alt="22WestMedia Radio Coverage Map"
                    />

                    <p className="advertisingUpdateTitle">
                        STATS <span>(*according to MMS Media )</span>
                    </p>
                    <p>
                        <NavLink
                            className="statsLink"
                            to={"https://info.mssmedia.com/"}
                            target="_blank"
                        >
                            https://info.mssmedia.com/
                        </NavLink>
                    </p>
                    <ul>
                        <li>61 percent of 25–34-year-olds utilize audio streaming.</li>
                        <li>
                            More than 75 percent of Gen Z uses music streaming services.
                        </li>
                        <li>
                            51% of adults ages 18-29 stream music{" "}
                            <NavLink
                                className="statsLink"
                                to={
                                    "https://pro.morningconsult.com/articles/young-adults-nearly-twice-as-likely-to-prefer-streaming-music-than-consumers-overall"
                                }
                            >
                                every day
                            </NavLink>
                            .
                        </li>
                        <li>
                            <NavLink
                                className="statsLink"
                                to={
                                    "https://morningconsult.com/wp-content/uploads/2020/01/200119_crosstabs_HOLLYWOOD_Adults_v1.pdf"
                                }
                            >
                                59 percent
                            </NavLink>{" "}
                            of adults ages 18-29 said streaming services are their favorite
                            way to listen to music.{" "}
                        </li>
                    </ul>

                    <p className="advertisingUpdateTitle">Cost Per Mile (CPM)</p>
                    <ul>
                        <li>Google Display Ads CPM: $38.40</li>
                        <li>Facebook Ads CPM: $8.60</li>
                        <li>22 West Radio CPM $1.16</li>
                    </ul>

                    <p className="advertisingUpdateTitle">
                        PER SPOT RATES <span>(Minimum Run = 5x)</span>
                    </p>

                    <div className="table-container">
                        <table className="responsive-table">
                            <thead>
                                <tr>
                                    <th></th>
                                    <th>INTERNET</th>
                                    <th>88.1 FM HD3*</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>5x</td>
                                    <td>$20 each = $100</td>
                                    <td>$15 each = $75</td>
                                </tr>
                                <tr>
                                    <td>10x</td>
                                    <td>$18 each = $180</td>
                                    <td>$12 each - $120</td>
                                </tr>
                                <tr>
                                    <td>20x</td>
                                    <td>$15 each = $300</td>
                                    <td>$10 each = $200</td>
                                </tr>
                                <tr>
                                    <td>21 – 60x</td>
                                    <td>$12 each </td>
                                    <td>$8 each</td>
                                </tr>
                                <tr>
                                    <td>61+</td>
                                    <td>See bundled rate</td>
                                    <td>See bundled rate</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                    <p className="advertisingUpdateTitle">
                        Bundles <span>(Run Of Schedule (24/7) )</span>
                    </p>

                    <div className="table-container">
                        <table className="responsive-table">
                            <tbody>
                                <tr>
                                    <td>1 spot every 2 hours</td>
                                    <td>9x per day</td>
                                    <td>270 spots per month</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <br /><br /><br />

                    <div className="table-container">
                        <table className="responsive-table">
                            <thead>
                                <tr>
                                    <th>$350 per 1 month</th>
                                    <th>$600 per 2 months</th>
                                    <th>$750 per 3 months</th>
                                    <th>$1000 per 4 months</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td></td>
                                    <td>$50 savings</td>
                                    <td>$300 savings</td>
                                    <td>$400 savings</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                    <p className="advertisingUpdateTitle">
                        <span>*Non-commercial copy only (see below)</span>
                        <br />
                        NON-COMMERCIAL “SPOTS” on 22 West Radio FM
                    </p>

                    <p>
                        Due to the non-commercial regulations governing our HD-3 signal, we cannot accept commercial advertising on 22 West HD FM. Credits are not intended to directly sell a product, service or event, though  they certainly may have this effect. In general, the FCC requires that sponsorship messages meet non-commercial requirements.
                    </p>

                    <p>
                        NON-COMMERCIAL GUIDELINES:
                    </p>

                    <ul>
                        <li>
                            Underwriting (non-commercial) spots must be 30 seconds or less and begin with “Programming sponsored by...” + Credits may include information including location(s), brand names and description of services, phone numbers and URLs.
                        </li>
                        <li>
                            Trademark slogans are acceptable if they are not promotional in nature.
                        </li>
                        <li>
                            Brief and clearly objective descriptive phrases are allowed. Calls to action, selling language, comparatives and superlatives are not allowed.
                        </li>
                        <li>
                            Price, rate, and “deal” information is not allowed. + Credits should not address the audience directly or personalize this message (do not use the word “You”).  Underwriting spots are about the company, not the company’s customers.
                        </li>
                    </ul>
                    <br />


                </div>

                <AdvertiseAgreementAccordian />

                <div className="agreementButtonContainer">
                    {/* <h1 className="advertiseTitle">Advertise with us</h1>
                    <NavLink
                        to="https://drive.google.com/file/d/11cXcVG9etP-12cTp436obLnZV828WTU5/view?usp=sharing"
                        target="_blank"
                        className="workOrderForm"
                    >
                        Web Advertising Agreement
                    </NavLink>
                    <NavLink
                        to="https://csulb.qualtrics.com/jfe/form/SV_cBfXNQ8COQGqA7k"
                        target="_blank"
                        className="workOrderForm"
                    >
                        Work Order Form
                    </NavLink> */}
                </div>
                 <h3>To advertise with 22 West Media, contact <a href="mailto:advertising@22westmedia.com" style={{color: "#4cc8f4" }}>advertising@22westmedia.com</a></h3>

            </div>
        </>
    );
};

export default RadioRates;
